export const GET_DATA_REQUEST = "GET_DATA_REQUEST"
export const GET_DATA_SUCCESS = "GET_DATA_SUCCESS"
export const GET_DATA_ERROR = "GET_DATA_ERROR"

export const PAYMENT_REQUEST = "PAYMENT_REQUEST"
export const PAYMENT_SUCCESS = "PAYMENT_SUCCESS"
export const PAYMENT_ERROR = "PAYMENT_ERROR"

export const PAYMENT_INTENT_REQUEST = "PAYMENT_INTENT_REQUEST"
export const PAYMENT_INTENT_SUCCESS = "PAYMENT_INTENT_SUCCESS"
export const PAYMENT_INTENT_ERROR = "PAYMENT_INTENT_ERROR"

export const PAYMENT_GCASH_REQUEST = "PAYMENT_GCASH_REQUEST"
export const PAYMENT_GCASH_SUCCESS = "PAYMENT_GCASH_SUCCESS"
export const PAYMENT_GCASH_ERROR = "PAYMENT_GCASH_ERROR"

export const PAYMENT_MAYA_REQUEST = "PAYMENT_MAYA_REQUEST"
export const PAYMENT_MAYA_SUCCESS = "PAYMENT_MAYA_SUCCESS"
export const PAYMENT_MAYA_ERROR = "PAYMENT_MAYA_ERROR"

export const PAYMENT_OTC_REQUEST = "PAYMENT_OTC_REQUEST"
export const PAYMENT_OTC_SUCCESS = "PAYMENT_OTC_SUCCESS"
export const PAYMENT_OTC_ERROR = "PAYMENT_OTC_ERROR"

export const SET_ITEMS_REQUEST = "SET_ITEMS_REQUEST"
export const SET_ITEMS_SUCCESS = "SET_ITEMS_SUCCESS"
export const SET_ITEMS_ERROR = "SET_ITEMS_ERROR"

export const SET_EMAIL_REQUEST = "SET_EMAIL_REQUEST"
export const SET_EMAIL_SUCCESS = "SET_EMAIL_SUCCESS"
export const SET_EMAIL_ERROR = "SET_EMAIL_ERROR"

export const SET_SOURCE_ID_REQUEST = "SET_SOURCE_ID_REQUEST"
export const SET_SOURCE_ID_SUCCESS = "SET_SOURCE_ID_SUCCESS"
export const SET_SOURCE_ID_ERROR = "SET_SOURCE_ID_ERROR"

export const SET_AMOUNT_TOTAL_REQUEST = "SET_AMOUNT_TOTAL_REQUEST"
export const SET_AMOUNT_TOTAL_SUCCESS = "SET_AMOUNT_TOTAL_SUCCESS"
export const SET_AMOUNT_TOTAL_ERROR = "SET_AMOUNT_TOTAL_ERROR"

export const GET_ITEMS_REQUEST = "GET_ITEMS_REQUEST"
export const GET_ITEMS_SUCCESS = "GET_ITEMS_SUCCESS"
export const GET_ITEMS_ERROR = "GET_ITEMS_ERROR"

export const GET_TRANSACTION_ID_REQUEST = "GET_TRANSACTION_ID_REQUEST"
export const GET_TRANSACTION_ID_SUCCESS = "GET_TRANSACTION_ID_SUCCESS"
export const GET_TRANSACTION_ID_ERROR = "GET_TRANSACTION_ID_ERROR"

export const SIGNIN_REQUEST = "SIGNIN_REQUEST"
export const SIGNIN_SUCCESS = "SIGNIN_SUCCESS"
export const SIGNIN_ERROR = "SIGNIN_ERROR"

export const CHECK_REQUEST = "CHECK_REQUEST"
export const CHECK_SUCCESS = "CHECK_SUCCESS"
export const CHECK_ERROR = "CHECK_ERROR"

export const GET_CHECKOUTS_REQUESTS = "GET_CHECKOUTS_REQUESTS"
export const GET_CHECKOUTS_SUCCESS = "GET_CHECKOUTS_SUCCESS"
export const GET_CHECKOUTS_ERROR = "GET_CHECKOUTS_ERROR"
